import React from 'react';
import clsx from 'clsx';
import css from './logos.module.sass';

export const JsLogo: React.FC<SvgProps> = ({ size = 25, className, style }) => (
  <svg
    style={style}
    width={size * 2.5}
    height={size * 2.5}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2500 2500"
    fill="none"
    className={clsx(css.js, className)}>
    <circle cx="1250" cy="1250" r="1250" fill="#212126" />
    <path
      d="M1968.69 635.303H532L666.69 2095.86L1250.26 2261.37L1833.84 2095.86L1968.69 635.303Z"
      fill="#E6A329"
    />
    <path d="M1250.26 754.299V2137.6L1723.09 2003.64L1838.13 754.299H1250.26Z" fill="#F1BF22" />
    <path
      d="M1626.42 933.481H899.423L848.783 1480.33L849.835 1476.93H1467.47L1447.32 1708.29L1250.26 1765L1050.29 1707.4L1038.32 1567.37H856.792L882.678 1847.19L1250.26 1951.54L1618.01 1847.19L1668.65 1297.99H1013.16L1021.57 1115.01H1476.36L1483.16 1219.85H1666.31L1626.42 933.481Z"
      fill="white"
    />
    <path
      d="M1250.43 933.481H899.423L848.783 1480.33L849.835 1476.93H1250.43V1298.56H1038.72L1047.14 1115.57H1250.43V933.481ZM1038.72 1567.37H856.792L882.678 1847.02L1250.26 1951.22V1765.16L1050.29 1707.4L1038.72 1567.37Z"
      fill="#EBEBEB"
    />
    <path
      d="M1110.56 419.881H982.664V511.696H1202.05V239H1110.56V419.881ZM1517.94 240.456H1295V335.912C1324.77 365.681 1340.3 379.999 1384.14 423.116H1295V510.159H1517.94V423.278L1428.8 336.074H1517.94V240.456Z"
      fill="#EBEBEB"
    />
  </svg>
);
