import React from 'react';
import clsx from 'clsx';
import css from '../projects-icon.module.sass';

export enum ArrowDirection {
  left = 'left',
  right = 'right'
}

export const ArrowIcon: React.FC<SvgProps & { direction: ArrowDirection }> = ({
  direction,
  size,
  className
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    className={clsx(className, css.arrow, {
      [css.arrowLeft]: direction === ArrowDirection.left
    })}>
    <path
      className={css.arrowBg}
      d="M502.111 235.502L322.578 93.741c-7.75-6.119-18.074-7.252-26.939-2.957-9.011 4.366-14.608 13.354-14.608 23.454v65.372H10c-5.523 0-10 4.478-10 10v132.779c0 5.522 4.477 10 10 10h271.03v65.372c0 10.102 5.598 19.089 14.608 23.454 3.566 1.728 7.367 2.578 11.143 2.578 5.61-.001 11.163-1.877 15.796-5.535l179.534-141.763c6.285-4.962 9.889-12.433 9.889-20.496s-3.605-15.534-9.889-20.497zm-12.395 25.297L310.183 402.562c-2.375 1.874-4.668 1.215-5.823.655-1.001-.485-3.329-1.987-3.329-5.456v-44.319l40.585-30.561c4.412-3.322 5.295-9.592 1.973-14.004-3.322-4.411-9.591-5.294-14.004-1.973l-28.555 21.502v-6.018c0-5.522-4.477-10-10-10H20V199.61h271.03c5.523 0 10-4.478 10-10v-75.372c0-3.469 2.328-4.971 3.329-5.456 1.156-.561 3.45-1.221 5.824.656l137.479 108.556-45.859 34.532c-4.412 3.322-5.295 9.592-1.973 14.004 1.965 2.609 4.962 3.985 7.996 3.985 2.094 0 4.206-.656 6.007-2.013l50.058-37.694 25.825 20.391c1.451 1.146 2.284 2.896 2.284 4.8 0 1.905-.833 3.655-2.284 4.8z"
    />
    <path
      className={css.arrowBg}
      d="M372.02 297.469c-4.171 0-8.016-2.695-9.415-6.631-1.379-3.879-.204-8.327 2.946-10.991 3.284-2.777 8.09-3.121 11.745-.863 3.497 2.16 5.336 6.417 4.523 10.445-.931 4.617-5.085 8.04-9.799 8.04z"
    />
  </svg>
);
