import React from 'react';
import clsx from 'clsx';
import css from './projects-icon.module.sass';

export const CloseIcon: React.FC<SvgProps> = ({ size, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    className={clsx(className, css.closeIcon)}>
    <path
      className={css.closeIconBg}
      d="M256 10c132.55 0 246 113.45 246 246S388.55 502 256 502 10 388.55 10 256 123.45 10 256 10z"
    />
    <path
      className={css.closeIconColor}
      d="M347.21 164.79c11.72 11.71 11.72 30.71 0 42.42L298.42 256l48.79 48.79c11.72 11.71 11.72 30.71 0 42.42-5.86 5.86-13.53 8.79-21.21 8.79s-15.35-2.93-21.21-8.79L256 298.42l-48.79 48.79c-5.86 5.86-13.53 8.79-21.21 8.79s-15.35-2.93-21.21-8.79c-11.72-11.71-11.72-30.71 0-42.42L213.58 256l-48.79-48.79c-11.72-11.71-11.72-30.71 0-42.42 11.72-11.72 30.7-11.72 42.42 0L256 213.58l48.79-48.79c11.72-11.72 30.7-11.72 42.42 0z"
    />
    <path d="M491.375 157.662c-13.15-30.297-31.856-57.697-55.598-81.439s-51.142-42.448-81.439-55.598C322.809 6.939 289.723 0 256 0s-66.809 6.939-98.338 20.625c-30.297 13.15-57.697 31.856-81.439 55.598s-42.448 51.142-55.598 81.439C6.939 189.191 0 222.277 0 256s6.939 66.809 20.625 98.338c13.15 30.297 31.856 57.697 55.598 81.439s51.142 42.448 81.439 55.598C189.191 505.061 222.277 512 256 512s66.809-6.939 98.338-20.625c30.297-13.15 57.697-31.856 81.439-55.598s42.448-51.142 55.598-81.439C505.061 322.809 512 289.723 512 256s-6.939-66.809-20.625-98.338zM256 492C128.075 492 20 383.925 20 256S128.075 20 256 20s236 108.075 236 236-108.075 236-236 236z" />
    <path d="M451.975 173.804c-10.87-25.256-26.363-48.044-46.049-67.729-19.686-19.686-42.473-35.179-67.729-46.049C311.948 48.728 284.293 43 256 43c-38.462 0-78.555 13.134-115.945 37.981-4.6 3.057-5.851 9.264-2.794 13.863 3.057 4.6 9.265 5.85 13.863 2.794C185.224 74.978 221.489 63 256 63c104.617 0 193 88.383 193 193s-88.383 193-193 193S63 360.617 63 256c0-34.504 11.975-70.771 34.629-104.877 3.056-4.601 1.804-10.807-2.796-13.863-4.602-3.056-10.808-1.803-13.863 2.797C56.13 177.454 43 217.546 43 256c0 28.293 5.728 55.948 17.025 82.196 10.87 25.256 26.363 48.044 46.049 67.729 19.686 19.686 42.473 35.179 67.729 46.049C200.052 463.272 227.707 469 256 469s55.948-5.728 82.196-17.025c25.256-10.87 48.044-26.363 67.729-46.049 19.686-19.686 35.179-42.473 46.049-67.729C463.272 311.948 469 284.293 469 256s-5.728-55.948-17.025-82.196z" />
    <path d="M114 105c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM146 326c0 10.687 4.163 20.731 11.719 28.281C165.276 361.838 175.32 366 186 366s20.724-4.162 28.281-11.719L256 312.562l41.719 41.719C305.276 361.838 315.32 366 326 366s20.724-4.162 28.278-11.716C361.837 346.731 366 336.687 366 326s-4.163-20.731-11.719-28.281L312.562 256l41.716-41.716C361.837 206.731 366 196.687 366 186s-4.163-20.731-11.719-28.281C346.724 150.162 336.681 146 326 146s-20.724 4.162-28.281 11.719L256 199.438l-41.719-41.719C206.724 150.162 196.681 146 186 146s-20.724 4.162-28.278 11.716C150.163 165.269 146 175.313 146 186s4.163 20.731 11.719 28.281L199.438 256l-41.716 41.716C150.163 305.269 146 315.313 146 326zm25.861-14.139l48.79-48.79c3.905-3.905 3.905-10.237 0-14.142l-48.793-48.793C168.081 196.361 166 191.341 166 186s2.081-10.361 5.861-14.139c3.78-3.779 8.801-5.861 14.139-5.861s10.359 2.082 14.139 5.861l48.79 48.79c3.905 3.905 10.237 3.905 14.143 0l48.79-48.791c3.779-3.778 8.8-5.86 14.138-5.86s10.359 2.082 14.142 5.864C343.919 175.639 346 180.659 346 186s-2.081 10.361-5.861 14.139l-48.79 48.791c-3.905 3.905-3.905 10.237 0 14.142l48.793 48.793C343.919 315.639 346 320.659 346 326s-2.081 10.361-5.861 14.139C336.359 343.918 331.338 346 326 346s-10.359-2.082-14.139-5.861l-48.79-48.79c-1.953-1.953-4.512-2.929-7.071-2.929s-5.119.976-7.071 2.929l-48.79 48.79C196.359 343.918 191.338 346 186 346s-10.359-2.082-14.142-5.864C168.081 336.361 166 331.341 166 326s2.081-10.361 5.861-14.139z" />
  </svg>
);
